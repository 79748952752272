import React from 'react'
import {
  CrudFilters,
  getDefaultFilter,
  IResourceComponentsProps,
  useTranslate,
} from '@refinedev/core'
import { List, useTable } from '@refinedev/antd'
import { ICallback } from 'interfaces'
import CallbacksTable from './list_table'
import { Form, Input, Select, Tag, SelectProps, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import BooleanTag from 'components/BooleanTag'

export const CallbacksList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { tableProps, searchFormProps, filters } = useTable<ICallback>({
    onSearch: (params) => {
      // @ts-ignore
      const { id, operation_id, from, parsed, valid_sign, outer_id } = params
      const callbacksFilters: CrudFilters = []

      callbacksFilters.push({
        field: 'id',
        operator: 'eq',
        value: id ? id : null,
      })
      callbacksFilters.push({
        field: 'operation_id',
        operator: 'eq',
        value: operation_id ? operation_id : null,
      })
      callbacksFilters.push({
        field: 'from',
        operator: 'eq',
        value: from ? from : null,
      })
      callbacksFilters.push({
        field: 'parsed',
        operator: 'eq',
        value: parsed !== undefined ? parsed : null,
      })
      callbacksFilters.push({
        field: 'valid_sign',
        operator: 'eq',
        value: valid_sign !== undefined ? valid_sign : null,
      })
      callbacksFilters.push({
        field: 'outer_id',
        operator: 'eq',
        value: outer_id ? outer_id : null,
      })

      return callbacksFilters
    },
    pagination: {
      pageSize: 20,
    },
  })

  var timerId: any
  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }
  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  return (
    <List>
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={onValueChange}
        initialValues={{
          id: getDefaultFilter('id', filters),
          operation_id: getDefaultFilter('operation_id', filters),
          from: getDefaultFilter('from', filters),
          parsed: getDefaultFilter('parsed', filters),
          valid_sign: getDefaultFilter('valid_sign', filters),
          outer_id: getDefaultFilter('outer_id', filters),
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item
            name="id"
            style={{
              width: 'calc(50% - 8px)',
            }}
          >
            <Input
              placeholder={t('callbacks.filter.id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="operation_id"
            style={{
              width: 'calc(50% - 8px)',
            }}
          >
            <Input
              placeholder={t('callbacks.filter.operation_id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item
            name="from"
            style={{
              width: 'calc(50% - 8px)',
            }}
          >
            <Input
              placeholder={t('callbacks.filter.from.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="outer_id"
            style={{
              width: 'calc(50% - 8px)',
            }}
          >
            <Input
              placeholder={t('callbacks.filter.outer_id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item
            name="parsed"
            style={{
              width: 'calc(50% - 8px)',
            }}
          >
            <Select
              allowClear
              placeholder={t('callbacks.filter.parsed.placeholder')}
              options={[
                { label: <Space><BooleanTag value={true} /></Space>, value: true },
                { label: <Space><BooleanTag value={false} /></Space>, value: false },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="valid_sign"
            style={{
              width: 'calc(50% - 8px)',
            }}
          >
            <Select
              allowClear
              placeholder={t('callbacks.filter.valid_sign.placeholder')}
              options={[
                { label: <Space><BooleanTag value={true} /></Space>, value: true },
                { label: <Space><BooleanTag value={false} /></Space>, value: false },
              ]}
            />
          </Form.Item>
        </div>
      </Form>
      <CallbacksTable tableProps={tableProps} />
    </List>
  )
}
