import {
  IResourceComponentsProps,
  useTranslate,
} from '@refinedev/core'

import { Space, Table, TableProps, Tag } from 'antd'
import { ICallback } from 'interfaces'

import DateField from 'components/DateField'
import { ShortId } from 'components/ShortId'
import BooleanTag from 'components/BooleanTag'

const CallbacksTable: React.FC<
  IResourceComponentsProps & {
    tableProps: TableProps<ICallback>
  }
> = ({ tableProps }) => {
  const t = useTranslate()

  return (
    <Table
      {...tableProps}
      rowKey="id"
      pagination={{
        ...tableProps.pagination,
        position: ['bottomLeft'],
        size: 'small',
      }}
    >
      <Table.Column<ICallback>
        dataIndex="id"
        ellipsis
        key="id"
        title={t('callbacks.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<ICallback>
        ellipsis
        dataIndex="from"
        key="from"
        title={t('callbacks.fields.from')}
      />
      <Table.Column<ICallback>
        dataIndex="parsed"
        key="parsed"
        title={t('callbacks.fields.parsed')}
        render={(value) => <BooleanTag value={value} />}
      />
      <Table.Column<ICallback>
        dataIndex="valid_sign"
        key="valid_sign"
        title={t('callbacks.fields.valid_sign')}
        render={(value) => <BooleanTag value={value} />}
      />
      <Table.Column<ICallback>
        dataIndex="operation_id"
        ellipsis
        key="operation_id"
        title={t('callbacks.fields.operation_id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<ICallback>
        ellipsis
        dataIndex="data_errors"
        key="data_errors"
        title={t('callbacks.fields.data_errors')}
        render={(values) => (
          <Space
            direction='vertical'
          >
            {values?.map((error: string) => <Tag color='red'>{error}</Tag>)}
          </Space>
        )}
      />
      <Table.Column<ICallback>
        dataIndex="payload"
        key="payload"
        title={t('callbacks.fields.payload')}
        render={(value) => <pre>{JSON.stringify(value, null, 2)}</pre>}
      />
      <Table.Column<ICallback>
        dataIndex="headers"
        key="headers"
        title={t('callbacks.fields.headers')}
        render={(value) => <pre>{JSON.stringify(value, null, 2)}</pre>}
      />
      <Table.Column<ICallback>
        dataIndex="created_at"
        key="created_at"
        title={t('callbacks.fields.created_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
      <Table.Column<ICallback>
        dataIndex="updated_at"
        key="updated_at"
        title={t('callbacks.fields.updated_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
    </Table>
  )
}

export default CallbacksTable
